

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL9PostLabPart1D',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part4: null,
      },
      questions: [
        {
          text: 'When you come to lab next week to perform your procedure and gather data for the analysis, what possible sources of error will you be looking to minimize? What kinds of things would make your measurements more accurate? And less accurate?',
          inputName: 'part4',
        },
      ],
    };
  },
};
